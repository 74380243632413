import React, { useState, useEffect } from 'react';
import _ from 'underscore';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import Badge from '@material-ui/core/Badge';

import Loader from '../Components/Loader';
import AppContainer from '../Components/AppContainer';
import MadeWithLove from '../Components/MadeWithLove';

import {downloadObjectData, camelToRead, isDate} from '../Utils/DataUtils';
import withStyle from '../style';

import Networker from '../Utils/Networker';

const getSession = async () => {
  try {
    const res = await Networker.getUserSession();
    return res.body;
  } catch (e) {
    return {};
  }
};

const UserStat = React.memo(({name, value}) => {
  const date = isDate(value);
  if (name === 'alerts') {
    return null;
  }
  return <div>
    <Typography variant="body1">
      {name !== '_id' ? camelToRead(name) + ': ' : 'User: '}
    </Typography>
    <Typography variant="h6" noWrap>
        {typeof value === 'object' ? value.length :
          date ? new Date(value).toLocaleString() : value}
    </Typography>
</div>
});

const UserSummary = React.memo(({user}) => {
  const numAlerts = (user.alerts || []).length;
  return <ListItem divider dense>
      <Grid container justifyContent="flex-start" alignItems="flex-start">
        <Grid item xs={12} container spacing={1} alignItems="center" justifyContent="flex-start">
          {Object.entries(user).map(([key, value]) => {
            return <Grid item xs={4} key={key}>
              <UserStat name={key} value={value}/>
            </Grid>
          })}
        </Grid>
        <Grid item xs={12}>
          <Badge badgeContent={numAlerts} color="error" overlap="rectangular">
          <List dense disablePadding
            subheader={
              <Typography>Alerts</Typography>
            }>
            {(user.alerts || []).map(text => {
              return <ListItem key={text} divider>
                <ListItemText primary={text}>
                </ListItemText>
              </ListItem>
            })}
          </List>
        </Badge>
        </Grid>
      </Grid>
  </ListItem>
});


export default function OrganizationInfo({match}) {
  const [loading, setLoading] = useState(false);
  const [organization, setOrganization] = useState();
  const [stats, setStats] = useState(null);
  const [visibleStats, setVisibleStats] = useState([]);
  const [filter, setFilter] = useState('');
  const [alertsToInclude, setAlertFilter] = useState([]);
  const [isSuper, setIsSuper] = useState(false);

  useEffect(() => {
    setStats(null);
    setLoading(true);
    const orgId = match.params.orgId;
    getSession()
    .then(async sess => {
      setIsSuper(sess.isSuperUser);
      const res = await Networker.get({
        root: 'organizations',
        query: {_id: orgId}
      });
      setOrganization(res.body[0]);
      const stats = await Networker.get({
        root: 'organizations',
        inner: orgId + '/stats',
        query: sess.isSuperUser ? {email: true} : {}
      });
      setStats(stats.body);
      const alertClasses = new Set();
      stats.body.userStats.forEach(({alerts}) => {
        alerts.forEach(alertClasses.add, alertClasses);
      });
      setAlertFilter(Array.from(alertClasses));
      setLoading(false);
    })
    .catch(err => {
      window.alert('Error loading stats');
      console.error(err);
      setLoading(false);
    })
  }, [match.params.orgId]);

  const handleFilterChange = _.debounce((val) => {
    setFilter(val);
  }, 50);

  useEffect(() => {
    if (stats) {
        console.info('hi')
      const newVisibleStats = stats.userStats.filter(({_id}) => {
        return _id?.includes(filter);
      });
      setVisibleStats(newVisibleStats.sort((a, b) => {
        const aUse = new Date(a.lastUse).valueOf();
        const bUse = new Date(b.lastUse).valueOf();
        return bUse - aUse;
      }));
    }
  }, [alertsToInclude, filter, stats])

  const classes = withStyle();

  return <div className={classes.root}>
      <CssBaseline />
      <AppContainer classes={classes}
        title={`${(organization || {}).name} Summary`} match={match}>
        <div className={classes.appBarSpacer}/>
        <Container maxWidth="md">
        {organization ? <Grid container alignItems="center" justifyContent="space-around" spacing={3}>
          <Grid item xs={12} md={3}>
            <Avatar alt={organization.name} src={organization.iconURL}
              className={classes.bigAvatar}>
              {organization.name.substr(0, 1)}
            </Avatar>
          </Grid>
          <Grid item xs={12} md={5}>
            <Typography variant="h3">
              {organization.name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="h5">
              Tier: {organization.tier}
            </Typography>
          </Grid>
          <Divider/>
        </Grid> : null}
        <Card>
          <CardHeader
            title="Summary">
          </CardHeader>
          <CardContent>
            {stats ? <Grid item xs={12} container alignItems="center" justifyContent="center">
            {Object.entries(stats).map(([key, value]) => {
              if (typeof value === 'number') {
                return <Grid item xs={12} md={4} key={key}>
                  <Typography variant="body1">
                    {camelToRead(key)}
                  </Typography>
                  <Typography variant="h6">
                    {value}
                  </Typography>
                </Grid>
              }
              return null;
            })}
          </Grid> : null}
          </CardContent>
        </Card>
        <Grid container item xs={12} alignItems="center">
          <Grid item xs={4}>
            <Typography variant="h4" display="inline">
              Users
            </Typography>&nbsp;
             {
               isSuper ? '' :
               <small style={{display: 'inline'}}>
                 (Emails masked)
               </small>
             }
           </Grid>
           {stats ? <Grid item xs={12} md={4}>
           <Button className={classes.iconFill} onClick={() => {
               downloadObjectData(visibleStats.map(obj => {
                 const newObj = Object.assign({}, obj, {UserID: obj._id});
                 alertsToInclude.forEach(alert => {
                   newObj[alert] = obj.alerts.includes(alert) ? 1 : '';
                 });
                 newObj.activeLabels = obj.activeLabels.length;
                 delete newObj.alerts;
                 delete newObj._id;
                 return newObj;
               }), organization.name, !isSuper);
             }} id={organization._id}>
             <DownloadIcon fontSize="large" id={organization.name}/>
           </Button>
          </Grid> : null}
          {stats ? <Grid item xs={12} md={4}>
           <TextField label="Filter"
             defaultValue=""
             onChange={(e) => {
               handleFilterChange(e.target.value);
             }}
             fullWidth autoFocus></TextField>
          </Grid> : null}
      </Grid>
      {stats ? <List dense>
        {visibleStats.map((user) => <UserSummary user={user} key={user._id}/>)}
      </List> : null}
    </Container>
      <MadeWithLove />
      <Loader show={loading} />
      </AppContainer>
    </div>;
}
